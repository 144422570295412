import React from "react";
import Typed from "react-typed";
import MainLayout from "../layout/MainLayout";
import { JobItem } from "../components/JobItem";
import { graphql, Link } from "gatsby";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { JobList } from "../components/JobList";
import { getAvatarLetters } from "../helpers";
import SEO from "../components/SEO";

export default ({ data }) => {
  return (
    <MainLayout>
      <SEO
        title={`All Companies hiring Salesforce Talent`}
        desc={`A list of Salesforce-friendly companies. Find the right fit for you from our library of amazing companies using Salesforce.`}
      />
      <div
        className="jumbotron jumbotron-fluid"
        style={{
          background: "#F7F9FC",
          borderBottom: "1px solid #DEE0F2",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h1 className="display-4">All Salesforce-friendly Companies</h1>
              <div className="lead">
                A list of Salesforce-friendly companies.
                <br />
                Find the right fit for you from our library of amazing companies using Salesforce.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {data.allCompaniesYaml.nodes.map((company) => (
            <div className="col-lg-6">
              <div className={`card mb-1 pt-2 pb-2 pl-2`}>
                <div className="row no-gutters">
                  <div className="col-1" style={{ display: "flex", alignItems: "center" }}>
                    {/* {company.image && <img src={company.image} className="card-img p-2" />} */}
                    <div
                      className="h4 m-0"
                      style={{
                        flex: 1,
                        textAlign: "center",
                        color: "#DAD6D3",
                      }}
                    >
                      {getAvatarLetters(company.id)}
                    </div>
                  </div>
                  <div className="col-11" style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="card-body job-info pt-2 pb-2 pl-1">
                        <h5 className="card-title mb-0">{company.title}</h5>
                      </div>
                    </div>
                    <Link to={`/companies/${company.id}`} className="stretched-link" style={{ fontSize: 0 }}>
                      View Salesforce Jobs at {company.title}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  {
    allCompaniesYaml(sort: { order: ASC, fields: title }) {
      nodes {
        id
        title
        fields {
          jobs {
            id
          }
        }
      }
    }
  }
`;
